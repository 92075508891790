import React from "react"
import WistiaHelper from '../utils/wistiaHelper'

class JsBottom extends React.Component {

  componentDidMount() {
    // Used for Wistia video iframe to boost performance
    setTimeout(function () {
      let iframe = document.querySelector('iframe')
      if (!iframe) return
      let src = iframe.getAttribute('data-src')
      iframe.setAttribute('src', src)
    }, 1000)

    // Used for Wistia video inline embed to boost performance
    setTimeout(function () {
      // WistiaHelper.initInlineEmbed()
    }, 1000)
  }
  render() {

    return (
        <React.Fragment>
        </React.Fragment>
    )
  }
}

export default JsBottom
