import React from "react"
import '../css/navigation-menu.scss'
import githubLogo from '../images/github-logo.png'
import hamburgerIcon from '../images/hamburger-icon.svg'
import closeIcon from '../images/close-x-icon.jpg'

class NavigationMenu extends React.Component {

  state = { showSideMenu: false }

  onHamburgerMenuClick = () => {
    this.setState({showSideMenu: true})
  }

  closeSideNavigationMenu  = () => {
    this.setState({showSideMenu: false})
  }

  render() {

    return (
      <React.Fragment>
        <nav className="is-hidden-mobile">
          <ul>
            <li><a href="/features">Features</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/calculator">Calculator</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/download">Download</a></li>
            <li><a href="https://github.com/invizi/invizi" target="_blank" rel="noopener noreferrer"><img src={githubLogo} className="nav--github" alt="github-logo"/></a></li>
          </ul>
        </nav>
        <nav className="mobile is-hidden-tablet">

          <a href="#" onClick={this.onHamburgerMenuClick} className={this.state.showSideMenu ? 'hamburger-menu invisible' : 'hamburger-menu'}>
            <img className="hamburger" src={hamburgerIcon} alt="menu-icon"/>
          </a>
          <div className={this.state.showSideMenu ? 'nav-mobile-menu show-side-navigation-menu' : 'nav-mobile-menu'}>
            <div className="mobile-nav-close">
              <a href="#" onClick={this.closeSideNavigationMenu}><img className="close" src={closeIcon} alt="close-icon"/></a>
            </div>
            <ul>
              <li><a href="/">HOME</a></li>
              <li><a href="/features">FEATURES</a></li>
              <li><a href="/contact">CONTACT</a></li>
              <li><a href="/calculator">CALCULATOR</a></li>
              <li><a href="/about">ABOUT</a></li>
              <li><a href="/download">DOWNLOAD</a></li>
              <li><a href="https://github.com/invizi" target="_blank" rel="noopener noreferrer">SOURCE CODE</a></li>
            </ul>
          </div>
        </nav>
      </React.Fragment>
    )
  }
}

export default NavigationMenu
