import React from "react"

// import { rhythm, scale } from "../utils/typography"

import NavigationMenu from "../components/navigationMenu"
import JsBottom from "../components/jsBottom"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { config } from "@fortawesome/fontawesome-svg-core";
import logo from "../images/invizi-logo-full-transparent.png"
import twitterLogo from '../images/twitter-logo.svg'

import { faSyncAlt, faChartLine, faUserLock, faSearch, faChartPie, faShieldAlt, faCode, faHourglass, faCheck, faDownload, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

library.add(faSyncAlt, faChartLine, faUserLock, faSearch, faGithub, faChartPie, faShieldAlt, faCode, faHourglass, faCheck, faDownload, faPlus, faMinus)
config.autoAddCss = false;

class Layout extends React.Component {
  render() {
    const { location, title, children, specialClass, logoClass } = this.props
    const myLogoClass = logoClass || 'logo'
    // const rootPath = `${__PATH_PREFIX__}/`

    let header

      header = (
        <React.Fragment>
          <div className="container">
            <div className="header-left">
              <a href="/"> <img src={logo} alt="logo" className={myLogoClass} alt="Invizi logo"/> </a>
            </div>
            <NavigationMenu/>
          </div>
        </React.Fragment>
      )
    let footer

    footer = (
      <div className="container flex">
        <div className="header-left col">
          © {new Date().getFullYear()}, <a href="/">Invizi</a>
        </div>
        <nav className='footer-right'>
          <ul>
            <li><a href="https://twitter.com/invizi_co" target="_blank" rel="noopener noreferrer"><img src={twitterLogo} className="nav--github filter-invert" alt="twitter" style={{'width': '20px'}} /></a></li>
            <li><a href="/credits">Credits</a></li>
            <li><a href="/privacy">Privacy</a></li>
            <li><a href="/terms">Terms</a></li>
          </ul>
        </nav>
      </div>
    )
    return (
      <div className="main-container">
        <div className="announcement-banner"></div>
        <header>{header}</header>
        <main className={specialClass}>{children}</main>
        <footer>{footer}</footer>
        <JsBottom/>
      </div>
    )
  }
}

export default Layout
