let WistiaHelper = {
  initInlineEmbed () {
    let videos = document.querySelectorAll('.wistia')
    videos.forEach(video => {
      video.classList.add('wistia_embed')
    })
  },

  onVideoReady (videoId) {
    window._wq = window._wq || []
    let promise = new Promise((resolve, reject) => {
      window._wq.push({
        id: videoId,
        onReady: function (video) {
          resolve(video)
        }
      })
    })
    return promise
  }
}

export default WistiaHelper
